.content-banner{
  position: relative;
  height: 500px;
  max-height: 80vh;
  top: 0px;

  @at-root #{&}__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    opacity: .5;
  } //__overlay

  @at-root #{&}__text{
    max-width: 900px;
  } //__text

  @at-root #{&}__title{
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 20px;
  } //__title

  @at-root #{&}__sub{
    font-size: 30px;
    font-weight: 400;
  } //__sub

  @at-root #{&}__img{
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //background-attachment: fixed;
  } //__img
} //content-banner

.content{
  @at-root #{&}__title{
    padding-bottom: 35px !important;
  }
  @at-root #{&}__h3{
    h3{
      font-weight: 900;
      font-size: 24px;
      color: $color-black;
    }
  }
}

.breadcrumb {
  margin: 0;
  color: #fff;
  font-size: 18px;

  &>li{
    a{
      color: #fff;
    } //a
    &+li{
      &:before{
        color: #fff;
      } //before
    } //li
  } //li

  &>.active{
    color: #fff;
  } //active
} //breadcrumb

.content-menu{
  width: 240px;
  padding-top: 20px;
  padding-bottom: 50px;

  @at-root #{&}_active {
    a{
      color: #08486d !important;
      &:after{
        content: '>';
        color: #fff;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      } //after
    } //a
  } //_active
  li{
    position: relative;

    &:not(:last-of-type){
      &:before{
        content: '';
        background-color: #fff;
        height: 1px;
        width: 185px;
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: .37;
        box-shadow: -2px 2px 2px 7% rgba(0,0,0,.5);
      } //before
    } //not last


    &:hover{
      a{
        color: #08486d !important;
      } //a
    } //hover
    a{
      color: #fff;
      font-size: 20px;
      line-height: 40px;
      transition: .3s;

      @include respond-to('medium'){
        line-height: 33px;
        font-size: 16px;
      } //---medium
    } //a
  } //li
} //content-menu

.content-wrapper{
  overflow: hidden;
  position: relative;
  display: table;
}

.content-menu-side{
  max-width: 560px;
  min-width: 350px;
  width: 25%;
  background-color: #44abe8;
  top: 0;
  right: 0;
  display: table-cell;
  height: 100%;

  @include respond-to('medium'){
    padding-top: 80px;
    min-width: 250px;
  } //---medium
  @include respond-to('small'){
    transform: translateX(100%);
    position: absolute;
    z-index: 1;
    transition: .3s;
  } //---small
  @at-root #{&}__btn{
    background-color: $color-aqua;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 150px;
    left: -50px;
    display: none;
    cursor: pointer;

    &:after{
      content: '';
      width: 15px;
      height: 15px;
      position: absolute;
      transform: translateY(-50%) rotate(-45deg);
      top: 50%;
      left: 28%;
      border: 2px solid transparent;
      border-left-color: #fff;
      border-top-color: #fff;
      transition: .5s;
    }

    @include respond-to('small'){
      display: block;
    } //---small
  } //__btn
  @at-root #{&}_visible{
    transform: translateX(0);
    .content-menu-side__btn{
      &:after{
        transform: translateY(-50%) rotate(135deg);
      } //after
    } //content-menu-side
  } //_visible
} //content-menu-side

.content-page{
  //display: table-cell;
  width: 100%;
}

.content-side{
  display: table-cell;
  padding-right: 50px;
  padding-bottom: 100px;
}
