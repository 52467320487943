.catalog-container{
  padding: 130px 15px 190px !important;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  .breadcrumb{
    li{
      color: #000;
      &:before{
        color: #000;
        font-weight: 500 !important;
      } //before
      a{
        color: #000;
      } //a
      &.active{
        font-weight: 600;
      }
    } //li
  } //breadcrumb
} //catalog-container

.catalog-form{
  width: 100%;
  margin-top: 25px;
  margin-bottom: 60px;
  padding: 40px 70px;
  background-color: #f1f1f1;
  border: solid 1px #d4d4d4;
  border-radius: 2px;
  position: relative;

  input[type='text'],select{
    max-width: 300px;
    width: 30%;
    height: 40px;
    background-color: #fff;
    border: solid 1px #d4d4d4;
    padding-right: 25px;
    margin-left: 30px;
    display: inline-block;
  } //text
  select{

  } //select
  input[type='submit']{
    background-color: #44abe8;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    padding: 0px 75px 4px;
    float: left;
    border: none;
    height: 40px;
  } //submit
} //catalog-form

.catalog-square{
  position: relative;
  background-color: #f9f9f9;
  box-shadow: none;
  transition: height .2s, background-color .2s, box-shadow .2s;
  width: 100%;
  height: 220px;
  padding: 20px;
  z-index: 0;
  border: solid 1px #d4d4d4;
  margin-bottom: 30px;

  @at-root #{&}_active{
    background-color: #fefefe;
    box-shadow: 0 7px 13px rgba(0,0,0,.25);
    height: 410px;
  } //_active

  @at-root #{&}_active-pos{
    position: absolute;
    z-index: 1;
    top: 0;
    right: 15px;
    width: calc(100% - 30px);
  } //_active-pos

  @at-root #{&}__container{
    position: relative;
    height: 250px;
  } //__container

  @at-root #{&}__title{
    font-size: 16px;
    color: #4f4f4f;
    margin: 0;
    font-weight: 600;
  } //__title
  @at-root #{&}__address{
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: -.02em;
    line-height: 25px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 10px;

    a{
      text-decoration: underline;
      color: #44abe8;
    } //a
  } //__address
  @at-root #{&}__text{
    font-size: 16px;
    color: #4f4f4f;
    letter-spacing: -.02em;
    line-height: 20px;
  } //__text
  @at-root #{&}__btn{
    position: absolute;
    bottom: 15px;
    left: 15px;
    height: 20px;
    width: 75px;
    color: #fff;
    font-size: 11px;
    letter-spacing: -.02em;
    background-color: $color-aqua;
    text-align: center;
    padding-top: 3px;
    cursor: pointer;
  } //__btn

  @at-root #{&}_hidden{
    display: none;
  } //_hidden
} //catalog-square
