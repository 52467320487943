body.scrolling .main-header{

} //main-header

.main-header{
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  transition: .2s;
}

.main-header_has-shadow{
  box-shadow: $header-shadow-x
              $header-shadow-y
              $header-shadow-size
              $header-shadow-spread
              $header-shadow-color;
}

.header-bg{
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  z-index: -1;
  transition: .7s;
  transform: translateY(0%);
}

.header-desktop{
  height: $general-header-height;
  position: relative;
  top: 0;
  transition: .2s;
  padding-top: 20px;

  @include respond-to('medium'){
    display: none;
  }
} //header-desktop

.header-wrapper{
  max-width: $max-width;
  padding: $padding-base;
} //header-wrapper

.header-logo{
  transition: .2s;
  position: relative;
  top: 0px;
  margin-left: 25px;
  //right: $padding-base;
} //header-logo__image

.header-logo__image{
  max-height: 100%;
  //height: 110px;
  transition: .2s;
} //header-logo__image

.header-main{
  max-width: 1680px;
  margin: 0 auto;
  position: relative;

  @at-root #{&}__phone{
    font-size: $header-font-size-big;
    text-align: right;
    position: relative;
  } //header-main__phone

  @at-root #{&}_color{
    @at-root #{&}_blue{
      color: $header-secondary-color;
    } //blue
  } //color
} //header-main



.header-main__secondary-menu{
  padding-right: $header-sec-spacing-r;
  display: inline;
} //header-main__secondary-menu

.header-main__secondary-item{
  display: inline-block;
  font-size: $header-font-size-small;

    a{
    color: inherit;
    font-size: inherit;
  }
} //header-main__secondary-item

.header-main__item{

} //header-main__item

.header-main__item_v-separator{
  &:after{
    content: '|';
    margin: $header-sec-separator-margin;
  }

  &:last-of-type{
    &:after{
      content: '';
    }
  }
} //header-main__item_v-separator

.header-main_main-color{
  color: $header-font-color;
} //header-main_main-color

.header-desktop__phone{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  span{
    display: block;
    color: #647383;
    font-size: 16px;

    &:last-of-type{
      color: #340c47;
      font-size: 22px;
    } //last
  } //span
} //header-desktop__phone

.header-therapists{
  position: absolute;
  left: 0px;
  margin-left: 9%;
  top: 50%;
  transform: translateY(-50%);

    .side-form__open, .header-search-link{
      font-weight: 700;
      display: inline-block;
      position: relative;
    } //side-form__open header-search-link

      .side-form__open{
        font-size: 20px;
        color: #340c47;
        margin-left: 20px;
        padding-left: 20px;
        cursor: pointer;

        &:after{
          content: '|';
          position: absolute;
          top: 0;
          left: 0;
          color: #d0cece;
        } //after

        @include respond-to('huge'){
          font-size: 17px;
          margin-left: 0px;
          padding-left: 10px;
        } //---huge
      } //side-form__open

      .header-search-link{
        font-size: 22px;
        color: #419fd6;
        padding-right: 20px;

        &:before{
          content: url(../images/header_loc.png);
          position: absolute;
          top: 5px;
          right: 0;
        } //before

        @include respond-to('huge'){
          font-size: 17px;
          padding-right: 10px;
        } //---huge
      } //header-search-link
      @include respond-to('large'){
        bottom: 0;
        top: unset;
        transform: none;
      } //---huge
} //header-therapists

//Header for mobile devices
.header-mobile{
  height: 50px;
  padding: 10px;
  display: none;

  @include respond-to('medium'){
    display: block;
  }

  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
    height: 4px;
    width: 30px;
  } //hamburger-inner

  .hamburger-inner:after{
    top: 8px;
  }

  .hamburger-inner:before{
    top: -8px;
  }

  .is-active{
    .hamburger-inner:after{
      top: 0;
    }
  }

  .header-mobile-logo{
    height: 100%;
    display: block;
    float: left;

    img{
      height: 100%;
    } //img
  } //header-mobile-logo

  @at-root #{&}__child-container{
    //overflow: hidden;
    //transition: .2s;
    //height: 0;

    @at-root #{&}_opened{
      //height: initial;
    } //_opened
  } //__child-container
  @at-root #{&}__child-item{
    font-size: 16px;
    border-top: 1px dashed $color-aqua !important;
    a{
      padding: .3em !important;
    }
  } //__child-item
  @at-root #{&}__child-menu{
    background-color: #fcfcfc;
    box-shadow: 0 0 25px rgba(0,0,0,.2) inset;
  } //__child-item

  .side-form{
    @at-root #{&}__open{
      font-size: 17px;
      color: #340c47;
      font-weight: 600;
      margin-right: 15px;
    } //__open
  } //side-form
} //header-mobile

.sb-slidebar{
  background-color: #fff;
  border-left: 1px solid $color-aqua;
  nav{
    ul{
      li{
        border-top: 1px solid $color-aqua;
        a{
          color: #5f5f5f;
          padding: .5em;
        }
      }
    }
  }

  &.sb-active{
    position: absolute;
    z-index: 3;
  }
}
