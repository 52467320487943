footer{
  width:100%;
  position: relative;
  background-color: #f1f1f1;
  z-index: 1;
  padding: 90px 0;

  @include respond-to('small'){
    padding: 30px 0 20px;
  } //---small
} //footer

.footer-container{
  max-width: 1350px;
  margin: 0 auto;
  //min-height: 315px;
  overflow: hidden;
  position: relative;

  @include respond-to('small'){
    overflow: auto;
    padding-bottom: 60px;
  } //---small
} //footer-container

.footer-box{
  float: right;
  position: relative;
  max-height: 100%;
  //height: 315px;
  box-sizing: border-box;

  @include respond-to('small'){
    height: unset;
  }
}

.footer-logo{
  width: 20%;
  border-left: solid 1px #dcd8d8;

  @include respond-to('medium'){
    width: 33%;
    border-color: transparent;
    padding: 2%;
  }
}

.footer-links{
  width: 56%;
  border-left: solid 1px #dcd8d8;
  color: #2c2037;
  opacity: .75;
  letter-spacing: -.02;
  padding:0px 15px;

  ul{
    padding:0px 10px;

    li{
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0px;

      &:first-of-type{
        font-size: 18px;
        font-weight: 600;
        border-bottom: 2px solid #2c2037;
        padding-bottom: 10px;
        line-height: unset;
      } //1st
      a{
        font-size: inherit;
        color: inherit;
      } //a
    } //li
  } //ul
  @include respond-to('medium'){
    width: 100%;
    padding-top: 30px;
  } //---small
} //footer-links

.footer-form{
  width: 24%;
  position: relative;
  float: left;

  @include respond-to('small'){
    width: 66%;
    padding: 0 10px;
  }

  @at-root #{&}__form{
    width: 300px;
    text-align: center;
    float: left;
    @include ph-color('#a19999');
    @include ph-size('16px');

    @include respond-to('small'){
      max-width: 300px;
      width: 100%;
    }
  } //__form
  @at-root #{&}__title{
    color: #5d5466;
    font-size: 22px;
    letter-spacing: -.02em;
    line-height: 24px;

    span{
      &:first-of-type{
        font-size: 24px;
        font-weight: 800;
      } //last
    } //span

    @include respond-to('small'){
      font-size: 12px;
      line-height: 14px;

      span{
        &:first-of-type{
          font-size: 16px;
          display: block;
        } //1st
      } //span
    } //---small
  } //__title
  @at-root #{&}__components{
    input{
      &[type='text']{
        width: 46%;
        float: right;
        height: 35px;
        border: solid 1px #e9e9e9;
        padding-right: 12px;
        margin-bottom: 20px;
        margin-top: 35px;

        &:first-of-type{
          margin-left: 8%;
        }

        @include respond-to('small'){
          width: 100%;
          height: 30px;
          margin-bottom: 0px;
          margin-top: 10px;

          &:first-of-type{
            margin-left: unset;
          } //1st
        } //---small
      } //text
      &[type='email']{
        width: 100%;
        height: 35px;
        border: solid 1px #e9e9e9;
        padding-right: 12px;
        margin-bottom: 40px;

        @include respond-to('small'){
          margin-top: 10px;
          margin-bottom: 10px;
          height: 30px;
        }

      } //email
      &[type='submit']{
        width: 100%;
        text-align: center;
        color: #fff;
        height: 50px;
        font-size: 28px;
        box-shadow: 1px 3px 5px rgba(0,0,0,.2);
        border: none;
        background-color: #419fd6;

        @include respond-to('small'){
          font-size: 20px;
          height: 30px;
        }
      } //submit
    } //input
  } //__components
} //footer-form

.footer-phone{
  position: absolute;
  width: 100%;
  text-align: center;

  span{
    display: block;
    color: #675e6f;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -.02em;


    &:last-of-type{
      color: #702c91;
      font-size: 29px;
      font-weight: 700;
      letter-spacing: .12em;
    } //last
  } //span

  @include respond-to('small'){
    span{
      display: block;
      font-size: 12px;

      &:last-of-type{
        font-size: 16px;
      } //last
    } //span
  } //---small
} //footer-phone

.footer-credit{

}

.cs_logo_png{
  @include respond-to('small'){
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

  }
}
