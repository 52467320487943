.home-content{
  max-width: 1130px;
  text-align: center;
  margin: 0 auto;

  p{
    font-size: 20px;
    color: #000;
  } //p
  h1{
    font-size: 30px;
  }
} //home-content

.project-article-border{
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 40px;
  margin-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;
}

.project-box1{
  background-color: #fff;
  position: relative;
  z-index: 3;
}

.home-strip{
  width: calc(50% - 150px);
  height: 1px;
  background-color: $color-silver;
  float: right;
  position: relative;
  top: 17px;
}

.project-box2{
  background-color: #fff;
  padding-bottom: 80px !important;

  h1{
    float: right;
    color: $color-green;
    font-size: 30px;
    font-weight: 800;
    width: 297px;
    text-align: center;
    margin: 0 0 80px 0;
  }
} //project-box2

.brand-img{
  float: right;
  position: relative;

  &:not(:last-of-type){
    margin-left: 6%;
  }
}

.content-side{
  //margin-right: 660px;
  //width: calc(100% - 560px);
  position: relative;
  //min-height: 1000px;

  p{
    font-size: 18px;
    color: #504a48;
    line-height: 28px;
    text-align: justify;
  } //p

  @include respond-to('medium'){
    p{
      font-size: 14px;
      line-height: 19px;
    } //p
  } //---medium
} //content-side

.content-title{
  text-align: right;
  margin: 60px 0;
  width: 100%;

  h1{
    color: #393939;
    font-size: 50px;
    font-weight: 800;
    padding:0;

    @include respond-to('medium'){
      font-size: 25px;
    } //---medium
  } //h1
} //content-title

.content-text{
  text-align: justify !important;
  font-size: 18px !important;
  color: #000;
  padding-left: 90px;
  padding-bottom: 50px;
  width: 75%;
  float: right;

  @include respond-to('medium'){
    font-size: 15px !important;
    padding-left: 40px;
    width: 100%;
  } //---medium
}

.content-page{
  display: table;
}

.content-row{
  display: table-row;
}

.content-image{
  float: right;
}

.menu-side{
  width: 250px;
  position: relative;
  margin-right: 35px;


  ul{
    width: 100%;
    border-top: solid 1px silver;

    li{
      border-bottom: solid 1px silver;
      width: 100%;
      padding: 12px 0 13px;

      a{
        font-size: 18px;
        font-weight: 600;
        color: $color-grey;
        width: 100%;
        display: block;
      } //a

      &.content-item-selected, &:hover{
        border-bottom: solid 2px $color-green;
        padding-bottom: 12px;

        a{
          color: $color-green;
        } //a
      } //content-item-selected
    } //li
  } //ul

  h2{
    font-size: 26px;
    color: #000;
    font-weight: 600;
    margin-top: 75px;
    padding-bottom: 20px;
  } //h2
} //menu-side

.project-comunity{
  color: #fff;
  float: left;
  margin-top: 100px;
  text-align: justify;

  &:hover{
    color: #fff;
  }

  @at-root #{&}__text{
    width: 610px;
  } //__text
  @at-root #{&}__title{
    font-size: 30px;
    font-weight: 900;
    border-bottom: solid 1px #fff;
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: 20px;

    @at-root #{&}_black-border{
      border-bottom-color: #000;
    } //_black-border
    @at-root #{&}_silver-border{
      border-bottom-color: #d9d9d9;
    } //_black-border
  } //__title
  @at-root #{&}__content{
    font-size: 20px;
  } //__content
  @at-root #{&}__more-info{
    font-size: 20px;
    padding-right: 45px;
    border-top: solid 1px #fff;
    padding-top: 10px;
    margin-top: 35px;
    position: relative;
    display: inline-block;

    @at-root #{&}_grey-border{
      border-top-color: #777;
    } //_black-border
    @at-root #{&}_silver-border{
      border-top-color: #d9d9d9;
    } //_black-border

    &:after{
      content: url(../images/plus_sign_white.png);
      position: absolute;
      right: 0;
      top: 13px;
    } //after
    @at-root #{&}_green-plus{
      &:after{
        content: url(../images/plus_sign_green.png);
      } //after
    } // _green-plus
  } //__more-info
} //project-comunity


body.blackandwhite{
  .main-banner__title{
    color: #fff !important;
  } //main-banner__title

  .home-forms__title span{
    color: #fff !important;
  }

  .home-forms_bg_aqua{
    background-color: #000 !important;
  }
  .home-button__texts{
    span, h1{
      color: #fff !important;
    }
  }
  .home-button__title{
    color: #fff !important;
  }
  .home-button__big-text{
    p{
      color: #fff !important;
    }
  }
  .home-button__link{
    color: #fff !important;
  } //home-button__link
} //blackandwhite

body.heaveysightedwhite{
  .home-button__title{
    line-height: 85px;
  } //home-button__title
} //heaveysightedwhite

.accessibility_wrapper{
  z-index: 3 !important;
}

.sb-slidebar{
  z-index: 10 !important;
}
