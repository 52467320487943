.home-scroller{
  width: 30px;
  height: 45px;
  border-radius: 15px;
  border: solid 2px #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -95px;
  box-sizing: border-box;

  &:hover{
    cursor: pointer;
  }

  .home-scroller-ball{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 7px;

    -webkit-animation: home-scroller 2s infinite;
    animation: home-scroller 2s infinite;
  } //home-scroller-ball
} //home-scroller
