body.scrolling{

}

.header-main__sub-container{
	background-color: $Header-menu-hover-color;
	position: absolute;
	right: $header-sub-offset-h;
	width: $header-sub-width;
	box-shadow: $header-sub-shadow-v-h-s $color-shadow-black;
	display: none;
	opacity: 0;
} //header-main__sub-container

.header-main__main-sub{

} //header-main__main-sub
.header-main__sub-row{

} //header-main__sub-row
.header-main__sub-list{
	padding: $header-sub-padding-v $header-sub-padding-h;
	float: right;
	width: $header-sub-column-width;
} //header-main__sub-list
.header-main__sub-item{
	//display: inline-block;
	a{
		font-size: $header-font-size-small;
		color: $color-black;
		line-height: $header-sub-line-height;

		&:hover{
			color: $color-light-blue;
		}
	}
} //header-main__sub-item
.header-main__child-menu{
	position: absolute;
	display: none;
	top: 20px;
	right: 0px;
	padding: 15px 0;

	&:hover{
		display: block;
	}
}
.header-main__child-item{
	display: block;
	margin-bottom: 0px;
	background-color: #fff;
	padding: 5px 30px;
	border-radius: 0;
	line-height: 40px;
	border: solid 1px rgba(0,0,0,0.2);
	border-bottom: 2px solid #702c91;
	width: 300px;

	&:hover{
		background-color: #f9f9f9;
	}

	&:not(:first-of-type){
		border-top-color: transparent;
	}
}
.header-main__menu{
	//margin-left: $header-menu-spaces * -.5;
	margin-left: 20px;
	margin-top: $header-menu-spacing-top;
	transition: .2s;
}
.header-main__item{
	display: inline-block;
  font-size: $header-font-size-medium;
	padding-right: $header-menu-spaces / 2;
	padding-left: $header-menu-spaces / 2;
	padding-top: $header-menu-items-v-padding;
	padding-bottom: $header-menu-items-v-padding;
	border-bottom: 	$header-selected-border-type
									$header-selected-border-size
									transparent;
	border-radius: 4px;
	font-weight: 600;
	position: relative;

	a{
		color: $header-font-color;
		font-weight: $header-font-weight;
		display: block;
		width: 100%;
		height: 100%;
	}

	&:hover{
		//background-color: $Header-menu-hover-color;
		//border-bottom-color: $header-selected-border-color;
		//color: $Header-menu-hover-color !important;
		//background-color: $header-menu-hover-bg;
		.header-main__child-menu{
			display: block;
		}
		a{
			//color: inherit;
			//background-color: inherit;
		}
		/*text-shadow: 	$header-menu-shadow-x
									$header-menu-shadow-y
									$header-menu-shadow-size
									$header-menu-shadow-color;*/
	}

	@at-root #{&}_shadow{
		text-shadow: 	$header-menu-shadow-x
									$header-menu-shadow-y
									$header-menu-shadow-size
									$header-menu-shadow-color;
	} //_shadow

	@at-root #{&}_space-reduced{
		padding-left: $header-menu-space-reduced / 2;
		padding-right: $header-menu-space-reduced / 2;
	} //_space-reduced

	@at-root #{&}_border{
		@at-root #{&}_none{
			border: none !important;
		} //_none
	} //_border_none

	@include respond-to('huge'){
		font-size: 15px;
		padding: 9px 5px;
	}
} //header-main__item
.header-main__item_parent{
  position: relative;

	&:after{
    content: '';
		border-right: 5px solid #000;
		border-bottom: 5px solid #000;
		border-top: 5px solid transparent;
		border-left: 5px solid transparent;
    position: absolute;
    top: 14px;
    left: 10px;
    transform: rotate(45deg);
  }

  &:hover{
		.header-main__sub-container{
			display: block;
			opacity: 1;

			-webkit-animation: AN-sub-display .3s; /* Safari 4+ */
  		-moz-animation:    AN-sub-display .3s; /* Fx 5+ */
		  -o-animation:      AN-sub-display .3s; /* Opera 12+ */
		  animation:         AN-sub-display .3s; /* IE 10+, Fx 29+ */
		}
    /*.header-main__sub-menu{
      display: block;
    } //header-main__sub-menu*/
  } //hover


} //header-main__item_parent
/*.header-main__sub-menu{
  display: none;
  position: absolute;
  top: $header-sub-distance;
  right: 0;
  border: solid 1px $header-secondary-color;
  border-radius: 3px;

  &:before{
    content: '';
    width: 100%;
    height: $header-sub-distance * .66;
    position: absolute;
    top: $header-sub-distance * -.33;
    right: 0px;
  }

} //header-main__sub-menu*/
/*.header-main__sub-item{
  padding: $header-sub-padding-v $header-sub-padding-h;
	transition: .2s;

	&:hover{
		background-color: $header-item-hover;
	}

  a{
    white-space: pre;
		font-size: $header-font-size-small;
  }
} //header-main__sub-item
*/
