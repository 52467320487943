.main-banner{
  position: relative;
  height: 90vh;
  min-height: 800px;

  @include respond-to('medium'){
    margin-top: 50px;
    height: unset;
    min-height: unset;
  }

  @at-root #{&}__link{
    display: block;
  } //__link

  @at-root #{&}__single{
    position: relative;
  } //__single
  @at-root #{&}__texts{
    position: absolute;
    top: 40vh;
    margin-right: $banner-texts-right;
    width: 800px;

    @include respond-to('medium'){
      font-size: 40px;
      line-height: 45px;
      padding: 20px;
      width: 100%;
      top: 0;

      img{
        max-width: 33%;
      }
    }
  } //__texts
  @at-root #{&}__title{
    font-size: $banner-title-size;
    font-weight: $banner-title-weight;
    line-height: $banner-title-height;
    letter-spacing: $banner-title-spacing;
    margin-bottom: $banner-sub-bottom-space;
    position: relative;
    text-shadow: -2px 5px 10px rgba(0,0,0,.30);

    @include respond-to('small'){
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      padding-right: 10px;
    }
  } //__title
  @at-root #{&}__sub{
    font-size: $banner-sub-size;
    font-weight: $banner-sub-weight;
    line-height: $banner-sub-height;
    margin-bottom: $banner-sub-bottom-space;
    letter-spacing: $banner-sub-spacing;
  } //__sub

  @at-root #{&}__overlay{
    width: 100%;
    height: 530px;
    background: linear-gradient(to top, #000 0, transparent 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: .5;
  } //__overlay
} //main-banner

.box{
  width: 100%;
  padding-top: $box-head-space;
  padding-bottom: $box-foot-space;
  position: relative;

  @at-root #{&}__strip{
    height: $box-strip-width;
    margin-top: $box-strip-top;

    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //__strip

  @at-root #{&}__title{
    text-align: center;
    position: relative;
    font-size: $box-title-size;
    padding: 0 0 40px;

    @at-root #{&}_has-separator{
      &:after{
        width: 100%;
        content: '';
        height: 2px;
        box-sizing: border-box;
        border-top: 1px solid #000;
        border-bottom: 1px solid #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: .15;
      }
    }

    @at-root #{&}_strong{
      font-weight: 700;
    }
  } //__title

  @at-root #{&}__sub-title{
    font-size: $box-subtitle-size;
    text-align: center;
    position: relative;
  } //__sub-title

  @at-root #{&}__content{
    font-size: $box-content-size;
    line-height: $box-content-line;
    position: relative;

    p{
      font-size: inherit;
      margin-bottom: $box-content-p-bottom;
    } //p
    @at-root #{&}_block{
      @at-root #{&}_s{
        width: $box-content-block-small;
        min-width: $box-content-block-small-min;
      } //_s
      @at-root #{&}_m{
        width: $box-content-block-medium;
        min-width: $box-content-block-medium-min;
      } //_m
      @at-root #{&}_l{
        width: $box-content-block-large;
        min-width: $box-content-block-large-min;
      } //_l
      @at-root #{&}_full{
        width: 100%;
      } //_l
    } //_block
  } //__content

  @at-root #{&}__img{
    @at-root #{&}_height{
      @at-root #{&}_full{
        max-width: unset;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
      } //_full
    } //_height
  } //__img

  @at-root #{&}__background{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    @at-root #{&}_color{
      @at-root #{&}_black{
        background-color: $color-black;
      } //_black
      @at-root #{&}_white{
        background-color: $color-white;
      } //_white
      @at-root #{&}_peach{
        background-color: $color-peach;
      } //_peach
    } //_color
    @at-root #{&}_opacity{
      @at-root #{&}_half{
        opacity: .5;
      } //_half
      @at-root #{&}_third{
        opacity: .33;
      } //_half
    } //_opacity
  } //__background

  @at-root #{&}_bg{
    @at-root #{&}_white{
      background-color: $color-white;
    } //_white
    @at-root #{&}_black{
      background-color: $color-black;
    } //_black
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
    @at-root #{&}_deep-blue{
      background-color: $color-deep-blue;
    } //_deep-blue
    @at-root #{&}_water-blue{
      background-color: $color-water-blue;
    } //_deep-blue
    @at-root #{&}_aqua{
      background-color: $color-aqua;
    } //_aqua
    @at-root #{&}_navy-blue{
      background-color: $color-navy-blue;
    } //_deep-blue
    @at-root #{&}_img{
      background-image: url($box-bg-img);
    } //_img
  } //_bg

  @at-root #{&}_border{
    @at-root #{&}_grey{
      border: solid $box-border-width $color-grey;
    } //_grey
  } //_border

  @at-root #{&}_align{
    @at-root #{&}_center{
      text-align: center;
    } //_center
  } //_align
} //box

.buttons-container{
  display: block;
  position: relative;
  text-align: center;
  margin-left: $button-spacing * -1;
  margin-right: $button-spacing * -1;
  padding-top: $button-head-space;

  @at-root #{&}__button{
    display: inline-block;
    margin: 0 $button-spacing / 2;
    width: $button-width;
  } //button
  @at-root #{&}__icon{
    display: block;
    margin: $center-margin;
  } //icon
  @at-root #{&}__title{

  } //title

  @at-root #{&}_padding-top{
    @at-root #{&}_small{
      padding-top: $buttons-container-head-space;
    } //_small
  } //_margin-top

  @at-root #{&}_padding-bottom{
    @at-root #{&}_small{
      padding-bottom: $buttons-container-foot-space;
    } //_small
  } //_padding-bottom

  @at-root #{&}_bg-color{
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //_bg-color
} //buttons-container

.slider-container{
  text-align: center;
  position: relative;
  margin-top: $slider-head-space;

  @at-root #{&}__item{
    padding: $slider-item-head-space $padding-base $slider-item-foot-space;
    position: relative;

    @at-root #{&}_size{
      @at-root #{&}_m{
        width: $slider-item-width;
        height: $slider-item-height;
      } //_m
    } //_size

    @at-root #{&}_onsale{
      &:after{
        content: url(/assets/images/onsale.png);
        position: absolute;
        top: 0;
        left: 0;
      } //after
    } //_onsale
  } //__item
  @at-root #{&}__image{
    display: block;
    margin: $center-margin;
  } //image
  @at-root #{&}__text{
    position: absolute;
    bottom: $slider-item-foot-space;
    width: 100%;
    margin-left: $padding-base * -1;
    margin-right: $padding-base * -1;
  } //__text
  @at-root #{&}__title{
    font-size: $slider-title-size;
    color: $slider-title-color;
    position: relative;

  } //__title
  @at-root #{&}__price{
    position: relative;
    font-size: $slider-price-size;
    color: $slider-price-color;
  } //__price
  @at-root #{&}__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    transition: .2s;

    &:hover{
      opacity: 1;
    } //hover
  } //__overlay

  @at-root #{&}__full-transparent{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: $slider-hover-opacity;
    transition: .2s;
  } //__full-transparent
} //slider-container

.home-news{
  width: 100%;
  height: 100%;
  position: relative;
  top: 5px;
  ul{
    width: 100%;
    height: 100%;
    li{
      width: 100%;
      height: 225px;
      padding-top: 20px;

      a{
        p{
          font-size: 16px;
        } //p
      } //a

      @include respond-to('small'){
        height: 100px;
        padding-top: 5px;
        a{
          p{
            font-size: 14px;
          }
        }
      } //small
    } //li
  } //ul
  @at-root #{&}__title{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 20px;
    margin-top: 5px;
    display: block;
    color: $color-aqua;

    @include respond-to('small'){
      font-size: 15px;
      margin-bottom: 5px;
      line-height: 15px;
      margin-top: 0px;
    }
  } //__title
  @at-root #{&}__date{
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
    display: block;
  } //__date
}
.home-forms{
  width: 27%;
  position: absolute;
  left: 0;
  top: 150px;

  @include respond-to('medium'){
    background: linear-gradient(to right, #6e2492 0, #6e2492 49.75%, #44abe8 50.25%, #44abe8 100%);
    position: relative;
    width: 100%;
    top: 0;
  }
  @include respond-to('small'){
    background: unset;
  }
/*  @include respond-to('small'){
    position: relative;
    top: 0;
    width: 100%;
  }*/


  @at-root #{&}_bg{
    @at-root #{&}_aqua{
      background-color: $color-aqua !important;
    } //_aqua
  } //_bg
  @at-root #{&}_color{
    @at-root #{&}_aqua{
      color: $color-aqua !important;
    } //_aqua
  } //_bg

  @at-root #{&}__ticker{
    background-color: #fff;
    width: 360px;
    height: 300px;
    padding: 30px;
    padding-top: 45px;
    padding-bottom: 30px;
    margin-bottom: 25px;
    text-align: right;
    position: relative;

    @include respond-to('medium'){
      width: 100%;
      height: unset;
      padding: 45px 30px 30px;
      margin-bottom: 0;
    }
    @include respond-to('small'){
      width: unset;
      padding: 30px 15px 0;
      height: 110px;
      margin-bottom: 0;
    }
  }

  @at-root #{&}__form{
    background-color: #fff;
    width: 360px;
    padding: 30px;
    padding-top: 45px;
    padding-bottom: 55px;
    margin-bottom: 25px;
    text-align: center;
    position: relative;
    //float: left;
    @include ph-color('#a19999');
    @include ph-size('16px');

    @include respond-to('medium'){
      padding: 45px 30px 40px;
      margin-bottom: 0;
      width: 100%;
    }
    @include respond-to('small'){
      //width: 100%;
      padding-bottom: 10px;
      margin: 0;
    }
  } //__form
  @at-root #{&}__title{
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #6e2492;
    padding-top: 10px;
    text-align: center;

    @include respond-to('small'){
      line-height: 6px;
      height: 30px;
    }

    span{
      &:first-of-type{
        font-size: 18px;
        font-weight: 500;
      } //last
    } //span
  } //__title
  @at-root #{&}__components{
    @at-root #{&}_margin-top{
      @at-root #{&}_none{
        margin-top: 0 !important;
      } //_none
    } //_margin-top
    @at-root #{&}_width{
      @at-root #{&}_full{
        width: 100% !important;
      } //_full
    } //_width
    select{
      background-color: #efeeee;
      margin-bottom: 40px;
      border: solid 1px #dcdcdc;
      height: 35px;

      @include respond-to('small'){
        height: 30px;
        margin-bottom: 25px;
      }
    } //select
    input{
      &[type='text']{
        width: 46%;
        float: right;
        height: 35px;
        border: solid 1px #dcdcdc;
        padding-right: 12px;
        margin-bottom: 20px;
        margin-top: 35px;
        background-color: #efeeee;

        @include respond-to('small'){
          margin-top: 0;
          height: 30px;
        }

        &:first-of-type{
          margin-left: 8%;
        }
      } //text
      &[type='email']{
        width: 100%;
        height: 35px;
        border: solid 1px #dcdcdc;
        padding-right: 12px;
        margin-bottom: 40px;
        background-color: #efeeee;

      } //email
      &[type='submit']{
        width: 100%;
        text-align: center;
        color: #6e2492;
        height: 50px;
        font-size: 28px;
        box-shadow: 1px 3px 5px rgba(0,0,0,.2);
        border: solid 1px #cbcaca;
        background-color: #fff;

        @include respond-to('small'){
          height: 35px;
          font-size: 23px;
          margin-bottom: 25px;
        }

      } //submit
    } //input
  } //__components
} //home-form

.slick-dots{
  top: 82vh;
  z-index: 1;

  li{
    &.slick-active{
      button{
        background-color: #000;
      }
    } //slick-active
    button{
      border-radius: 50%;
      background-color: #cdd3cd;
      border: none;
    } //button
  } //li
} //slick-dots

.home-button{
  display: block;

  &:hover{
    color: #fff;
  }
  @at-root #{&}__big-text{
    width: 400px;
    position: absolute;
    bottom: 45px;
    left: 35px;
    pointer-events: none;

    @include respond-to('small'){
      width: 100%;
      bottom: 0;
      right: unset;
      left: unset;
      padding: 0 30px 10px;
    }
  } //__big-text
  @at-root #{&}__big{
    color: #fff;

    &:hover{
      color: #fff;
    }

    p{
      color: inherit;
      font-size: 22px;
      font-weight: 300;
      letter-spacing: -.02em;

      @include respond-to('small'){
        font-size: 14px;
      }
    } //p
  } //__big
  @at-root #{&}__title{
    font-weight: 300;
    font-size: 80px;
    letter-spacing: -.02em;
    line-height: 75px;

    @include respond-to('small'){
      font-size: 25px;
      line-height: 45px;
    }
  } //__title
  @at-root #{&}__link{
    font-size: 28px;
    letter-spacing: -.02em;
    float: left;

    @include respond-to('small'){
      font-size: 15px;
    }
  } //__link

  @at-root #{&}__texts{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 3;
    padding: 40px;
    pointer-events: none;

    h1{
      font-size: 35px;
      font-weight: 700;
      color: #fff;
      display: block;
      text-shadow: 0 0 0 transparent;
    }

    span{
      color: #fff;
      display: block;

      &:first-of-type{
        font-size: 19px;
      } //1st
      &:nth-of-type(2){
        font-size: 35px;
        font-weight: 700;
      } //2nd
      &:last-of-type{
        font-size: 28px;
        font-weight: 300;
      } //last
    } //span

    @include respond-to('small'){
      bottom: unset;
      top: 0;
      padding: 15px 20px 15px 15px;

      h1{
        font-size: 18px;
        padding-bottom: 10px;
      }

      span{
        &:first-of-type{
          font-size: 12px;
          padding-bottom: 10px;
        } //1st
        &:nth-of-type(2){
          font-size: 18px;
          padding-bottom: 10px;
        } //2nd
        &:last-of-type{
          font-size: 14px;
        } //last
      } //span
    } //----small

  } //__texts
  position: relative;
  img{
    width: 100%;
  } //img

  @at-root #{&}_effect{
    @at-root #{&}_hover-shadow{
      span, p{
        text-shadow: none;
        transition: .4s;
      } //span p


      &:hover{
        span, p, h1{
          text-shadow: 4px 2px 10px black;
        } //span p
      } //hover
    } //_hover-shadow
  } //_effect
} //home-button
